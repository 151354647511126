<div class="modal__container">
    <div class="modal__header">
        <div class="title">
            <mat-icon>error</mat-icon>{{'global.pedencyDetails' | translate }}
        </div>
        <div>
            <label class="subtitle">{{data.iterator + 1}} - {{ 'global.' + data.title | translate }}</label>
        </div>
    </div>
    <div class="message__container">
        {{data.message}}
    </div>

    <div class="submit__button">
        <button type="button" [ngStyle]="{'background-color': color}"
            class="btn btnAction" (click)="closeModal()">{{'global.okUnderstanded' 
            | translate }}</button>
    </div>
</div>