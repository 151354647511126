export function buildCompanyItems(config, response) {

    response.model.items.map((item) => {
        const c = item.company;
        item.company = `${c.fullName} ${c.cnpj}`;

        if (!!c.addressStreet) {
            item.address = `${c.addressStreet} ${c.addressNumber} ${c.addressNeighborhood} ${c.addressCity} ${c.addressState} ${c.addressPostalCode} ${c.addressCountry}`;
        } else {
            item.address = null;
        }
        item.phones = c.phoneNumbers;
        item.email = c.email;
    });

    config = {
        ...config,
        rows: response.model.items,
        total: response.model.totalItems,
        page: response.model.page
    };

    return config;
}

export function findInList(id, list) {
    return list.find((i) => i.id === id);
}

export function buildOptionsList(array) {
    const tempArray = [];

    array.map((ar) => {
        tempArray.push({
            label: ar.name,
            value: ar.id
        });
    });
    return tempArray;
}

export function addOptionsList(value, list) {
    const formId = value.id;

    if (!formId) {
        const id = list.length + 1;
        list.push({ ...value, id });
    } else {
        const arrayItem = findInList(formId, list);
        list[list.indexOf(arrayItem)] = value;
    }
    return list;
}

export function removeOptionsList(item, rows) {
    const arrayItem = findInList(item.id, rows);

    if (arrayItem) {
        rows.splice(rows.indexOf(arrayItem), 1);
    }
    
    return rows;
}

export function removeReference(array) {
    return JSON.parse(JSON.stringify(array));
}