import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pendency-modal',
  templateUrl: './pendency-modal.component.html',
  styleUrls: ['./pendency-modal.component.scss']
})
export class PendencyModalComponent implements OnInit {

  color: string;
  constructor(public dialogRef: MatDialogRef<PendencyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.color = localStorage.getItem('COLOR');
    this.color = this.color ? this.color  : '#7fc845';
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

}
