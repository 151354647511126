import { TableConfig } from './table-config.model';

export class CompanyModel {
    id: string;
    fullName: string;
    shortName: string;
    cnpj: string;
    ie: string;
    phoneNumbers: string;
    addressStreet: string;
    addressNumber: number;
    addressComplement: string;
    addressNeighborhood: string;
    addressCity: string;
    addressState: string;
    addressPostalCode: string;
    addressCountry: string;
    contacts: string;
    email: string;
    webSite: string;
    comments: string;
    isInternal: boolean;
    isActive: boolean;
    dateCreated: string;
    userIdCreated: string;
    masterId?: string;
    custodianId?: string;
    administratorId?: string;
    consultantId?: string;
    managerId?: string;
    allowUpdate?: boolean;
    companyId?: string;
    itemId?: string;
    isDigitalSignature: boolean;

    selectId: string;
    selectLabel: string;

    configWitness: TableConfig;
    showWitness: boolean;

    certifying?: any;
}
